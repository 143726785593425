import React from 'react'
import {Avatar} from '../../../user'
import {USER_IMAGE_UPLOAD_LINK} from '../../../../services/constants'
import {formatLinks, getLinks, imageAppendUrl} from '../../../../services/utils'

export type CompanyData = {
  name?: string
  siret?: string
  description?: string
  websiteUrl?: string
  email?: string
  phone?: string
  logo?: string
  subtitle?: string
  users: UserDetailsData[]
  sector?: {
    libelle: string
  }
}
export type UserDetailsData = {
  id: number
  accounts: {
    enabled: boolean
  }[]
  position: string
  email: string
  avatar: string
  firstName: string
  lastName: string
  phone_number: string
}
const AnnuaireUserDetails = ({data}: {data: UserDetailsData}) => {
  // console.log(data);
  if (data.accounts[0] && data.accounts[0].enabled) {
    return (
      <div className="container-fluid border-bottom">
        <div className="row py-1">
          <div className="col-12 col-md-3 d-flex align-items-center py-2 py-sm-0 px-md-2">
            <div style={{marginLeft: 100}}>
              <Avatar alt={data.avatar} img={imageAppendUrl(USER_IMAGE_UPLOAD_LINK, data.avatar)} size="sm" />
            </div>
            <p className="ml-3 mb-0 text-primary-darker">
              {data.firstName} {data.lastName}
            </p>
          </div>
          <div className="col-12 col-md-9 row w-100 pl-5">
            <div className="d-flex align-items-center py-1 py-md-0 col-12 col-md-4">
              <p className="mb-0 pl-md-4 pr-md-2 d-flex flex-wrap">
                <span className="font-weight-bold mr-1 d-block d-md-none">Position:</span> {data.position}
              </p>
            </div>
            <div className="d-flex align-items-center py-1 py-md-0 col-12 col-md-4">
              <p className="mb-0 pl-md-3 pr-md-2 d-flex flex-wrap">
                <span className="font-weight-bold mr-1 d-block d-md-none">Adresse mail:</span>{' '}
                <a href={`mailto:${data.email}`} target="_blank" rel="noopener noreferrer">
                  {data.email}
                </a>
              </p>
            </div>
            {data.phone_number && (
              <div className="d-flex align-items-center py-1 py-md-0 col-12 col-md-4">
                <p className="mb-0 pl-md-2">
                  <span className="font-weight-bold mr-1 d-block d-md-none">Numéro de téléphone:</span>{' '}
                  <a href={`tel:${data.phone_number}`}>{data.phone_number}</a>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
  return null
}

const AnnuaireTableContentExpandRow = ({data}: {data: CompanyData}) => {
  return (
    <>
      <div className="d-block d-sm-none p-3 bg-light border-bottom">
        <p>
          <span className="font-weight-bold" style={{textDecoration: 'underline'}}>
            Secteur d'activité:
          </span>{' '}
          {data.sector && data.sector.libelle}
        </p>
        {data.description && (
          <p>
            <span className="font-weight-bold" style={{textDecoration: 'underline'}}>
              Description:
            </span>{' '}
            {data.description}
          </p>
        )}
        {data.websiteUrl && (
          <p>
            <span className="font-weight-bold" style={{textDecoration: 'underline'}}>
              Site internet:
            </span>{' '}
            <a href={getLinks(data.websiteUrl)} target="_blank" rel="noopener noreferrer">
              {formatLinks(data.websiteUrl)}
            </a>
          </p>
        )}
        {data.email && (
          <p>
            <span className="font-weight-bold" style={{textDecoration: 'underline'}}>
              Email:
            </span>{' '}
            <a href={`mailto:${data.email}`} target="_blank" rel="noopener noreferrer">
              {data.email}
            </a>
          </p>
        )}
        {data.phone && (
          <p>
            <span className="font-weight-bold" style={{textDecoration: 'underline'}}>
              Téléphone:
            </span>{' '}
            <a href={`tel:${data.phone}`}>{data.phone}</a>
          </p>
        )}
      </div>
      {data.users && data.users.map((el) => <AnnuaireUserDetails key={`d-${el.id}`} data={el} />)}
    </>
  )
}

export default AnnuaireTableContentExpandRow
