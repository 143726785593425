import React, { Component } from 'react';
import PostBody from './post_body';
import Comment from '../comment/comment';
import NewComment from '../comment/new-comment';
import api from '../../../services/api';
import { connect } from 'react-redux';
import CardHeaders from './card_header';
import { API_POST_LOAD, USER_IMAGE_UPLOAD_LINK, API_POST_DELETE } from '../../../services/constants';
import NotificationButton from '../../../component/notifications/snackbar/button-notification';
import { postDelete } from '../../../actions/postsAction';
import { imageAppendUrl } from '../../../services/utils';
import {logger} from "../../../services/logger";

class Post extends Component {
    constructor(props) {
        super();
        this.state = {
            show_comment: props.showComment ? true : false,
            new_comment: false,
            comments: [],
            post_type: props.post.postType.libelle,
            supprConfirm: false,
        }
        this.newComment = this.newComment.bind(this);
        this.addComment = this.addComment.bind(this);
        this.erasePost = this.erasePost.bind(this);
        this.updateComments = this.updateComments.bind(this);
    }

    hasLike = (comment_id, allLikes) => {
        var allComments = this.state.comments;
        for (var i = 0; i < allComments.length; i++) {
            if (allComments[i].id === comment_id) {
                allComments[i].likes = allLikes;
                break;
            }
        }
        this.setState({comments: allComments});
    }

    updateComments = (data) => {
        let allComments = this.state.comments;
        for (var i = 0; i < allComments.length; i++) {
            if (allComments[i].id === data.id) {
                allComments[i] = data;
                break;
            }
        }
        this.setState({comments: allComments, });
    }

    addComment = (data) => {
        this.state.comments.push(data);
        this.setState({new_comment: false, show_comment: true,});
        this.comment_nbr = this.comment_nbr + 1;
    }

    newComment = () => {
        this.setState({new_comment: !this.state.new_comment})
    }

    deleteCom (comment_id) {
        var comment;
        var all_comments = this.state.comments;
        for (comment in this.state.comments) {
            if (all_comments[comment].id === comment_id) {
                all_comments.splice(comment, 1);
                this.setState({comments: all_comments, });
                return;
            }
        }
    }

    erasePost = () => {
        api.delete(API_POST_DELETE(this.props.post.id), this.props.config)
        .then(res => {
            this.props.postDelete(this.props.post.id);
        })
        .catch(err => {
            logger.debug('Delete post error: ', err);
        })
    }

    dispComments = () => {
        this.setState({show_comment: !this.state.show_comment, });
    }

    componentDidMount() {
        api.get(API_POST_LOAD(this.props.post.id), this.props.config)
        .then(res => {
            for (var i = 0; i < res.data.post.comments.length; i++) {
                res.data.post.comments[i].user.avatar = imageAppendUrl(USER_IMAGE_UPLOAD_LINK, res.data.post.comments[i].user.avatar);
            }
            this.setState({comments: res.data.post.comments, })
        })
        .catch(err => {
            logger.debug("Get all comments ERROR: ", err);
        });
    }

    render() {
        if (!this.props.post || !this.props.post.postType) {
            // ! Code PA-003
            logger.debug('DEBUG -- Une erreur est survenue... Code PA-003');
            return null;
        }

        return (
            <div style={{marginBottom: 2.7 + 'rem'}}>
                <NotificationButton
                    data={{
                        notification_message: "Voulez-vous vraiment supprimer ce post ?",
                        button_title: "Supprimer"
                    }}
                    open={this.state.supprConfirm}
                    handleClose={() => this.setState({supprConfirm: false})}
                    returnAction={this.erasePost}
                    className="position-absolute"
                />
                <div className={"card mt-3 mb-n1 post-type-" + this.props.post.postType.libelle} style={{zIndex:1}}>
                    <CardHeaders post_type={this.props.post.postType.libelle} attachment={this.props.post.attachment}/>
                    <div className="card-body p-2">
                        <PostBody post={this.props.post} comments={this.state.comments && this.state.comments.length} supprConfirm={() => this.setState({supprConfirm:true})} showCommentVar={this.state.show_comment} returnValue={() => this.newComment()} showComment={this.dispComments.bind(this)}/>
                    </div>
                </div>
                {!this.props.post.pinned &&
                    <div className={"comment mt-1 " + (this.state.show_comment ? "" : "d-none")}>
                        {
                            this.state.comments &&
                            this.state.comments.map((element, i) => (<Comment key={element.id} updateComments={(data) => this.updateComments(data)} comment={element} post_id={this.props.post.id} onDelete={(comment_id) => this.deleteCom(comment_id)} hasLike={((comment_id, allLikes) => this.hasLike(comment_id, allLikes))}/>))
                        }
                    </div>
                }
                {
                    !this.props.post.pinned &&
                    this.state.new_comment &&
                    <div className="mt-n1">
                        <NewComment post={this.props.post} confirmReturn={(data) => this.addComment(data)}/>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    config: state.apiR,
    posts_update: state.postsR.updateHeader,
})

const mapDispatchToProps = dispatch => ({
    postDelete: payload => dispatch(postDelete(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Post);
