import React, { Component } from 'react';
import { Post } from './post';
import {connect} from 'react-redux';
import { postLoad, postFilter, adPostsLoad } from '../../actions/postsAction';
import api from '../../services/api';
import InfiniteLoader from 'react-infinite-loader';
import { removeToken } from '../../actions/apiAction';
import { withRouter } from 'react-router-dom';
import { API_POSTS_LOAD, POST_IMAGE_UPLOAD_LINK, CLUB_IMAGE_UPLOAD_LINK, USER_IMAGE_UPLOAD_LINK, API_GET_ADVERTS_POSTS, ADVERT_IMAGE_UPLOAD_LINK } from '../../services/constants';
import { imageAppendUrl, shuffle } from '../../services/utils';
import PostFilter from './filter/post_filter';
import Axios from 'axios';
import { AdPost } from './post/ad_post';
import {logger} from "../../services/logger";
import clubConfigApi from "../../api/clubConfigApi";
import { hostname } from "../../services/url";

class Posts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: 1,
            posts: props.postsR.posts,
            ad_posts: 1
        };
        this.loadItems = this.loadItems.bind(this);
    }

    componentDidMount() {
        this.loadItems();
        clubConfigApi.getClubConfigs()
        .then(config => {
            var club;
            for (club in config) {
                if (config[club]['subdomain_url'] === hostname) {
                    club = config[club];
                    this.setState({ad_frequence: club.ad_frequence});
                    return;
                }
            }
        })
        .catch(err => {
            logger.debug("Loading config.json ERROR: ", err);
        })
    }

    componentWillReceiveProps(newProps) {
        if (this.state.posts.length !== newProps.postsR.posts.length) {
            this.setState({posts: newProps.postsR.posts, });
        }
    }

    async loadItems() {
        if (this.state.loading_posts || this.props.postsR.posts.length === (10 * this.state.pagination)) {
            return;
        }
        await this.setState({loading_posts: true, });
        await api.get(API_POSTS_LOAD(this.state.pagination, this.state.posts.length), this.props.config)
        .then(async res => {
            for (var i = 0; i < res.data.post.length; i++) {
                if (res.data.post[i].postType.libelle !== "video") {
                    res.data.post[i].attachment = imageAppendUrl(POST_IMAGE_UPLOAD_LINK, res.data.post[i].attachment);
                }
                res.data.post[i].club.avatar = imageAppendUrl(CLUB_IMAGE_UPLOAD_LINK, res.data.post[i].club.avatar);
                if (res.data.post[i].user) {
                    res.data.post[i].user.avatar = imageAppendUrl(USER_IMAGE_UPLOAD_LINK, res.data.post[i].user.avatar);
                }
            }
            this.props.postLoad(res.data.post);
            await this.setState({pagination: this.state.pagination + 1, loading_posts: false, });
        })
        .catch(err => {
            if (err.response && err.response.status === 400) {
                console.error('Load posts ERROR', err);
                return;
            }
            console.error('Load posts ERROR', err);
            this.setState({loading_posts: false,});
            this.props.history.push('/logout');
        });
        api.get(API_GET_ADVERTS_POSTS, this.props.config)
        .then(res => {
            var adverts = res.data.map(element => {
                return {
                    ...element,
                    name: element.title,
                    logo: imageAppendUrl(ADVERT_IMAGE_UPLOAD_LINK, element.logo),
                    attachment: element.advertType.libellus !== 'video' ? imageAppendUrl(ADVERT_IMAGE_UPLOAD_LINK, element.attachment) : element.attachment,
                }
            });
            this.props.adPostsLoad(adverts);
        })
        .catch(err => {
            console.error('Load advert posts ERROR: ', err);
        })
    }

    render() {
        let added_ad_posts = 0;
        let random_post = shuffle(this.props.postsR.ad_posts);
        let repeat = 1;

        return (
            <div className="container p-0 pb-5">
                {
                    this.props.postsR.filter ?
                    <PostFilter />
                    :
                    <ul>
                        {
                            this.props.postsR.posts.map((element, i) => {
                                if (i === (this.state.ad_frequence * added_ad_posts * repeat) && this.props.postsR.ad_posts.length) {
                                    if (added_ad_posts >= random_post.length) {
                                        added_ad_posts = 0;
                                        repeat++;
                                    }
                                    var ad_post_nb = added_ad_posts;

                                    added_ad_posts++;

                                    if (element.pinned) {
                                        return (
                                            <>
                                                <li key={element.id}>
                                                    <Post post={element}/>
                                                </li>
                                                <li key={random_post[ad_post_nb] && random_post[ad_post_nb].id}>
                                                    <AdPost post={random_post[ad_post_nb]} />
                                                </li>
                                            </>
                                        )
                                    } else {
                                        return (
                                            <>
                                                <li key={random_post[ad_post_nb] && random_post[ad_post_nb].id}>
                                                    <AdPost post={random_post[ad_post_nb]} />
                                                </li>
                                                <li key={element.id}>
                                                    <Post post={element}/>
                                                </li>
                                            </>
                                        )
                                    }
                                }
                                return (
                                    <li key={`post-${element.id}`}>
                                        <Post post={element}/>
                                    </li>
                                )
                            })
                        }
                        <InfiniteLoader onVisited={() => setTimeout(this.loadItems(), 500)} loaderStyle={{display: "none"}}/>
                    </ul>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    postsR: state.postsR,
    config: state.apiR,
})

const mapDispatchToProps = dispatch => ({
    postLoad: (payload) => dispatch(postLoad(payload)),
    removeToken: (payload) => dispatch(removeToken(payload)),
    postFilter: (payload) => dispatch(postFilter(payload)),
    adPostsLoad: (payload) => dispatch(adPostsLoad(payload)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Posts));
